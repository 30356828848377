import React from "react";
import { StringParam, useQueryParam } from "use-query-params";

import { POS_PAGE_ROUTES, QUERY_PARAM } from "../../../core/constants";
import { redirect } from "../../../core/navigate";
import { LoadingMessage } from "../../../components";

const Initiate = () => {
  const [transactionId] = useQueryParam(
    QUERY_PARAM.TRANSACTION_ID,
    StringParam
  );

  if (transactionId) {
    redirect(`${POS_PAGE_ROUTES.Summary}?transactionId=${transactionId}`);
  }

  return (
    <LoadingMessage message="Please wait while we process your request.." />
  );
};

export default Initiate;
